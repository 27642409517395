import React from "react";

const SearchResult = ({searchResults}) => {
    return (
        <div className="results-grid">
            {searchResults.map((result) => (
                <div key={result.product_id} className="result-item">
                    <img src={result.image_urls[0]} alt={result.product_title} className="product-image"/>
                    <h3>{result.product_title}</h3>
                    <p>{result.product_description}</p>
                    <p>Price: {result.currency_code} {result.price.toFixed(2)}</p>
                </div>
            ))}
        </div>
    );
};

export default SearchResult;
