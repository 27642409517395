import React from "react";

const SearchForm = ({
                        searchText,
                        handleInputChange,
                        handleSearch,
                        genderFilter,
                        setGenderFilter,
                        sortOption,
                        setSortOption,
                    }) => {
    return (
        <form onSubmit={handleSearch} className="search-form">
            <input
                type="text"
                value={searchText}
                onChange={handleInputChange}
                placeholder="Search garments..."
                className="search-input"
            />
            <select value={genderFilter} onChange={(e) => setGenderFilter(e.target.value)}>
                <option value="">Filter by Gender</option>
                <option value="Men">Men</option>
                <option value="Women">Women</option>
            </select>
            <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                <option value="">Sort by</option>
                <option value="Price, Ascending">Price, Ascending</option>
                <option value="Price, Descending">Price, Descending</option>
                <option value="Name, Ascending">Name, Ascending
                </option>
                <option value="Name, Descending">Name, Descending</option>
            </select>
            <button type="submit" className="search-button">
                Search
            </button>
        </form>
    );
};

export default SearchForm;
