import React, {useEffect, useState} from "react";
import axios from "axios";
import "./App.css";
import SearchForm from "./components/SearchForm";
import SearchResult from "./components/SearchResult";

function App() {
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [genderFilter, setGenderFilter] = useState("");
    const [sortOption, setSortOption] = useState("");

    const handleInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const loadResults = async (searchPhrase, pageNumber, gender, sortBy) => {
        setIsLoading(true);
        const params = {
            page: pageNumber,
            ...(gender ? {gender} : {}),
            ...(sortBy ? {sort_by: sortBy} : {}),
        };
        const response = await axios.get(`/search/${encodeURIComponent(searchPhrase)}`, {params});
        setSearchResults((prevResults) => [...prevResults, ...response.data]);
        setIsLoading(false);
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        setSearchResults([]);
        setPage(1);
        loadResults(searchText, 1, genderFilter, sortOption);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !isLoading) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isLoading]);

    useEffect(() => {
        if (page > 1) {
            loadResults(searchText, page, genderFilter, sortOption);
        }
    }, [page]);

    return (
        <div className="App">
            <h1>Garment Search</h1>
            <SearchForm
                searchText={searchText}
                handleInputChange={handleInputChange}
                handleSearch={handleSearch}
                genderFilter={genderFilter}
                setGenderFilter={setGenderFilter}
                sortOption={sortOption}
                setSortOption={setSortOption}
            />
            <SearchResult searchResults={searchResults}/>
        </div>
    );
}

export default App;
